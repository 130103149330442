<template>
	<div class="wrapper">
		<div class="view" ref="view" @click="growImg($event)"></div>
		<div class="select">
			<div  class="uploadvideo">
				<span class="el-icon-picture" @click="openUpload" title="上传图片"></span>
			</div>
			<template >
				<el-upload v-show="imgUrl!=''"  :action="imageUrl" list-type="picture-card" :on-success="UserImgSuccess"
				 :on-remove="UserImgtRemove">
					<i class="el-icon-plus"></i>
				</el-upload>
			</template>
			<div class="uploadvideo">
				<input type="file" ref="upvideo" @change="onupdatevideo" accept=".mp4" title="上传视频">
				<span class="el-icon-video-camera"></span>
			</div>
			<div class="uploadvideo">
				<input type="file" ref="file" @change="onupdatefile" accept=".doc,.ppt,.pdf,.xls,.txt" title="上传文件">
				<span class="el-icon-folder"></span>
			</div>
	
		</div>
		<div class="input">
			<textarea :disabled="disabled" @keydown="entrySend($event)" ref="text" rows="5" name="S1" cols="20" />
			<button @click="sendText">发送</button>
    </div>
    <div class="mask" v-if="showImgWrap">
      <img :src="showImgUrl" alt />
      <i class="el-icon-close close" @click="closeImg"></i>
    </div>
  </div>
</template>

<script>
import { usergetinfo } from "@/request/user";

export default {
  props: ["userMessage"],
  data() {
    return {
      imgUrl: "",
      isShow: false,
      disabled: false,
      showImgUrl: "",
      showImgWrap: false,
	  video:"",
	  isfile:"",
    };
  },
  watch: {
    userMessage() {
      this.userTransmitMessage();
    }
  },
  created() {
    usergetinfo({ token: this.$token }).then(res => {
      if (res.code == 1) {
        this.myAvatar = res.data.avatar;
      } else {
        this.myAvatar = require("@/assets/image/Interrogation_expert_Headportrait@2x.png");
      }
    });
  },
  methods: {
	  onupdatevideo(){
	  		let formData = new FormData();
	  		console.log( this.$refs.upvideo.files[0]);
	  		formData.append("file", this.$refs.upvideo.files[0]);
	  		formData.append("token", this.$token);
	  		 this.$axios.post("http://hlwyaodian.jzbwlkj.com/api/file/upload",formData).then(res=>{
	  			 if(res.data.code==1){
	  				 this.video=res.data.data.file.url;
	  				  console.log(this.video); 
	  				  this.sendvideo();
	  			 }
	  			
	  		 });
	  		
	  },
	  onupdatefile(){
	  		let formData = new FormData();
	  		formData.append("file", this.$refs.file.files[0]);
	  		formData.append("token", this.$token);
	  		 this.$axios.post("http://hlwyaodian.jzbwlkj.com/api/file/upload",formData).then(res=>{
	  			 if(res.data.code==1){
	  				 this.isfile=res.data.data.file; 
	  				  this.sendfile();
	  			 }
	  			
	  		 });
	  		
	  },
    sendText() {
      if (this.imgUrl != "") {
		 console.log(1111);
        this.sendImg();
		return;
      }
	  if (this.video != "") {
	    this.sendvideo();
		return;
	  }
      if (this.$refs.text.value.trim() == "") return;

      // 给父组件传递聊天对话信息
      this.$emit("receiveMessage", this.$refs.text.value);
      // 读取img图片的数据
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span> ${this.$refs.text.value} </span> </div>`;
      div.className = "doctor chatbox";
      this.$refs.view.appendChild(div);
      this.$refs.text.value = "";
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    sendImg() {
      this.$emit("receiveMessage", this.imgUrl);
      let div = document.createElement("div");
      div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
                           <span class='img'> <img src='${this.imgUrl}'/> </span> </div>`;
      div.className = "doctor chatbox";
      this.$refs.view.appendChild(div);
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
      this.imgUrl = "";
    },
	sendvideo() {
	  this.$emit("receiveMessage", this.video);
	  let div = document.createElement("div");
	  div.innerHTML = `<div> <img  src="${this.myAvatar}"/>
	                       <span class='video'> <video src='${this.video}' controls="controls"/> </span> </div>`;
	  div.className = "doctor chatbox user1";
	  this.$refs.view.appendChild(div);
	  this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
	  this.video = ''
	},
	sendfile(){
		let url=this.isfile.url+"?file_name="+this.isfile.name;
		this.$emit("receiveMessage",url);
		let div = document.createElement("div");
		div.innerHTML = `<div class="isfile">
		                      <img  src="${this.myAvatar}"/>
		                       <span class='isfiles'>
							        <i class="el-icon-document"></i>${this.isfile.name}
							  </span> 
						</div>`;
		div.className = "doctor chatbox user1";
		this.$refs.view.appendChild(div);
		this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
		this.isfile = ''
	},
    entrySend(e) {
      if (this.$refs.text.value.trim() == "") return;
      if (e.keyCode === 13) {
        this.sendText();
      }
    },
    UserImgSuccess(response) {
      this.imgUrl = response.data.file.url;
    },
    UserImgtRemove() {
      this.imgUrl = "";
    },
    openUpload() {
      this.imgUrl = "";
      let div = document.getElementsByClassName("el-upload--picture-card")[0];
	  console.log(div);
      div.click();
    },

    // 用户传递的信息
    userTransmitMessage() {
      let avatar;
	  console.log(this.userMessage);
      if (
        this.userMessage.result.user_info &&
        this.userMessage.result.user_info.avatar &&
        "http://hlwyaodian.jzbwlkj.com/upload/" !=
          this.userMessage.result.user_info.avatar
      ) {
        avatar = this.userMessage.result.user_info.avatar;
      } else {
        avatar = require("@/assets/image/user.png");
      }
      // 传递的是图片
      if (this.userMessage.result.pic!=""&&this.userMessage.result.pic!=undefined) {
        
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span class='img'> <img src='${this.userMessage.result.pic}'/> </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      } else if(this.userMessage.result.video!="" &&this.userMessage.result.video!=undefined){
		  let div = document.createElement("div");
		  div.innerHTML = `<div> <img  src="${avatar}"/>
		                     <span>  <video src='${this.userMessage.result.video}' controls="controls"/></span> </div>`;
		  div.className = "user chatbox";
		  this.$refs.view.appendChild(div);
	  }else if(this.userMessage.result.file!=""&&this.userMessage.result.file!=undefined){
		  // 传递的是文件
		  let name=this.userMessage.result.file.slice(this.userMessage.result.file.indexOf("=")+1,this.userMessage.result.file.length);
		  console.log(name);
		  let url=this.userMessage.result.file.slice(0,this.userMessage.result.file.indexOf("?"));
		  let div = document.createElement("div");
		  div.innerHTML = `<div> <img  src="${avatar}"/>
		                     <span>
							 <a href="${url}" download="${name}"> <i class="el-icon-document">${name}</i> </a>
							 </span> 
							 </div>
							`;
		  div.className = "user chatbox";
		  this.$refs.view.appendChild(div);
	  }else {
        // 传递的是文本信息
        let div = document.createElement("div");
        div.innerHTML = `<div> <img  src="${avatar}"/>
                           <span> ${this.userMessage.result.message} </span> </div>`;
        div.className = "user chatbox";
        this.$refs.view.appendChild(div);
      }
      this.$refs.view.scrollTop = this.$refs.view.scrollHeight;
    },
    growImg(e) {
      if (e.target.nodeName === "IMG") {
        this.showImgWrap = true;
        this.showImgUrl = e.target.src;
      }
    },
    closeImg() {
      this.showImgUrl = "";
      this.showImgWrap = false;
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 62.5%;
  height: calc(100% - 66px);
  position: absolute;
  left: 0px;
  min-width: 750px;
}
.view {
  position: absolute;
  width: 100%;
  height: calc(100% - 160px);
  border-right: 1px solid #999;
  overflow: scroll;
}
.select {
  width: 100%;
  min-width: 750px;
  height: 40px;
  background: #ddd;
  position: absolute;
  border: 1px solid #999;
  z-index: 10;
  bottom: 140px;
  width: 100%;
  display: flex;
  img {
    width: 22px;
    height: 18px;
    margin-top: 8px;
    margin-left: 20px;
    cursor: pointer;
  }
  .uploadvideo{
  	  display: inline-block;
  	  position: relative;
  	  overflow: hidden;
  	  margin-left: 20px;
  	  top: 5px;
  	  input{
  		  position: absolute;
  		  opacity: 0;
  	  }
  	  span{
  		  font-size: 26px;
  		  color:#4D4D4D
  	  }
  }
}
.select /deep/ .el-upload {
  position: absolute;
  top: -160px;
  left: 20px;
}
.select /deep/ .el-upload-list__item {
  position: absolute;
  top: -160px;
  left: 20px;
  z-index: 999;
}
.input {
  width: 100%;
  height: 150px;
  border: 1px solid #999;
  position: absolute;
  bottom: -10px;
  textarea {
    width: 100%;
    height: 150px;
    font-size: 22px;
    border: 0px;
    resize: none;
  }
  button {
    width: 70px;
    height: 35px;
    border-radius: 8px;
    position: absolute;
    top: 100px;
    right: 10px;
    cursor: pointer;
  }
}
.chatbox {
  width: 300px;
  font-size: 30px;
  float: right;
  overflow: hidden;
  text-align: right;
  span {
    float: right;
  }
  img {
    float: right;
  }
}
.mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  text-align: center;
  img {
    width: 40%;
    margin-top: 100px;
  }
  .close {
    font-size: 50px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
