<template>
	<div class="disponseInfo">
		<div>
			<div>
				<span class="info">处方信息</span>
				<hr />
			</div>
			<div class="bill_wrap" v-if="disInfo.type==0">
				<div class="bill" >
					<div class="date">{{disInfo.add_time}}</div>
					<div class="invald">本处方单3个月内有效</div>
					<h3>处方笺</h3>
					<el-row>
						<el-col :span="12">
							科别:
							<span>{{ disInfo.inquiry_info.category_name }}</span>
						</el-col>
						<el-col :span="12">
							处方号:
							<span>{{ disInfo.inquiry_info.p_code }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="8">
							姓名:
							<span>{{ disInfo.inquiry_info.realname}}</span>
						</el-col>
						<el-col :span="8">
							年龄:
							<span>{{ disInfo.inquiry_info.age }}</span>
						</el-col>
						<el-col :span="8">
							性别:
							<span>{{ disInfo.inquiry_info.sex === 0 ? '保密' : (disInfo.inquiry_info.sex === 1 ? '男' : '女') }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							过敏史:
							<span>{{ disInfo.inquiry_info.allergy }}</span>
						</el-col>
						<el-col :span="12">
							遗传史:
							<span>{{ disInfo.inquiry_info.genetic_disease }}</span>
						</el-col>
					</el-row>
					<el-row>
						临床诊断:
						<span>{{ disInfo.inquiry_info.diagnosis}}</span>
					</el-row>
					<div class="content">
						<div class="type">R</div>
						<div class="name" v-for="(item, i) in disInfo.goods_list" :key="i">
							<el-row>
								<el-col :span="8">{{ item.goods_name }}</el-col>
								<el-col :span="8">{{item.spec}}</el-col>
								<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
							</el-row>
							<el-row>
								<el-col :span="4">用法</el-col>
								<el-col :span="5">{{ item.drugRoute_name}}</el-col>
								<el-col :span="5">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
								<el-col :span="5">{{item.drugFrequency_name}}</el-col>
								<el-col :span="5">服用{{item.drugDay}}天</el-col>
							</el-row>
						</div>
					</div>

					<div class="graph">
						<el-row>
							<el-col :span="12">
								<p>医生签字:</p>
								<img :src="doctorSign.audit_sign" alt />
							</el-col>
							<el-col :span="12">
								<p>药师签字:</p>
								<img :src="pharmSign.audit_sign" alt />
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="bill2">
					
				</div>
			</div>
			<div class="isimage" v-else>
				<img :src="disInfo.pic" alt="">
			</div>
			<div class="wrap_autograph" v-if="disInfo.status==3">
				<h3>
					<span>药师意见</span>
					<hr />
				</h3>
				<div class="content" v-if="disInfo.type==0">{{ disInfo.sign_list[1].note==""? "此药师没有填写意见" : pharmSign.note }}</div>
					<div class="content"  v-if="disInfo.type==2">{{ disInfo.sign_list[0].note==""? "此药师没有填写意见" : pharmSign.note }}</div>
				<div class="updata">
					<el-button type="primary" @click="updataDispose">修改处方单</el-button>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	// 获取处方单详情
	import {
		getInfo
	} from "@/request/personnel";

	export default {
		props: ["pCode"],
		data() {
			return {
				disInfo: {},
				doctorSign: {}, // 医生或专家的签名对象
				pharmSign: {} // 药师的签名对象
			};
		},
		created() {
			getInfo({
				token: this.$token,
				p_code: this.pCode
			}).then(res => {
				this.disInfo = res.data;
				console.log(this.disInfo );
				this.disInfo.sign_list.forEach(item => {
					if (item.cert_type == 1 || item.cert_type == 2) {
						this.doctorSign = item;
					} else if (item.cert_type == 3) {
						this.pharmSign = item;
					}
				});
			});
		},
		methods: {
			updataDispose() {
				this.$emit("changeState", 3, this.pCode);
			}
		}
	};
</script>

<style lang="less" scoped>
	.isimage{
		width: 800px;
		height: 500px;
		margin: 0 auto;
		img{
			display: block;
			height: 100%;
			margin: 0 auto;
		}
	}
	.disponseInfo {
		width: 1200px;
		min-height: 400px;
		color: #333;
		margin: 0 auto;
	}

	.disponseInfo>div {
		padding: 30px;
	}

	.disponseInfo .bill_wrap {
		background: #fbfbfb;
		margin-top: 30px;
		padding: 50px 0px;
	}

	.info {
		font-size: 24px;
	}

	hr {
		border: none;
		width: 1000px;
		height: 2px;
		display: inline-block;
		margin-left: 10px;
		margin-bottom: 5px;
		background-color: #f5f5f5 !important;
	}

	// 单据信息
	.disponseInfo .bill {
		box-sizing: border-box;
		background: #fff;
		margin-left: 200px;
		box-shadow: 5px 5px 5px #ccc;
		width: 600px;
		min-height: 744px;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
		}

		h3 {
			font-size: 28px;
			font-weight: 500;
			text-align: center;
			margin: 10px 0px;
		}

		>.el-row {
			border-bottom: 1px solid #424242;
			line-height: 40px;
		}

		.content {
			min-height: 100px;
			padding: 10px 0px 15px 0px;

			.el-row {
				border: none;
				padding: 3px 0;

				.el-col {
					margin: 3px 0;
				}

				.el-col:nth-child(2) {
					text-align: center;
				}

				.el-col:nth-child(4) {
					text-indent: 2em;
				}

				.el-col:nth-child(5) {
					text-align: center;
				}

				.el-col:nth-child(3) {
					text-align: right;
				}

				.el-col:nth-child(6) {
					text-align: right;
				}

				.el-col {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.type {
				font-size: 22px;
				font-weight: 700;
			}

			.name {
				margin: 3px 0;
			}
		}

		.graph {
			border-top: 1px solid #424242;
			height: 150px;

			.el-row {
				margin-top: 12px;

				img {
					width: 100%;
				}
			}
		}
	}

	.disponseInfo .autograph {
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 13px;
		color: #999;
		margin-left: -25px;
		cursor: pointer;
	}

	.disponseInfo div.wrap_autograph {
		padding: 0px;
		width: 1200px;
		margin-top: 40px;
		margin-bottom: 40px;

		h3 {
			margin-left: 40px;
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 25px;
			color: #333;
			margin-right: 30px;
		}

		.updata {
			text-align: center;
		}
	}

	.wrap_autograph .content {
		background: #fbfbfb;
		padding: 25px;
		width: 1200px;
		height: 132px;
		font-size: 18px;
		color: #333;
		margin-bottom: 30px;
		font-size: 18px;
	}

	.el-button.el-button--primary {
		background-color: #38eec1;
		border: #38eec1;
	}
</style>
