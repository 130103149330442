<template>
	<div class="modifyPre">
		<div v-if="disInfo.type==0">
			<div class="case-box">
				<div class="case-info">
					<div class="case-title">
						<h4>处方单</h4>
					</div>
					<div class="case-top">
						<div class="case-top-info">
							<div class="case-left">
								<p>姓名：<span>{{disInfo.inquiry_info.realname}}</span></p>
								<p>年龄：<span>{{disInfo.inquiry_info.age}}</span></p>
								<p>性别：<span>{{disInfo.inquiry_info.sex===0?"保密":(disInfo.inquiry_info.sex===1)?"男":"女"}}</span></p>
								<p>体重：<span>{{disInfo.inquiry_info.weight==""?"无":disInfo.inquiry_info.weight+"kg"}}</span></p>
							</div>
							<div class="case-right">
								<p>身高：<span>{{disInfo.inquiry_info.height==""?"无":disInfo.inquiry_info.height+"cm"}}</span></p>
								<p>过敏史：<span>{{disInfo.inquiry_info.allergy}}</span></p>
								<p>遗传史：<span>{{disInfo.inquiry_info.genetic_disease}}</span></p>
							</div>
						</div>
					</div>
					<div class="case-content">
						<div class="case-left">
							<p>诊疗科目：<span>{{disInfo.inquiry_info.category_name}}</span></p>
						</div>
						<div class="case-right">
							<p>诊疗医师：<span>{{disInfo.add_username}}</span></p>
						</div>

					</div>
					<div class="case-content">
						<div style="width: 100%;">
							<div class="disease-change">
								<span>疾病名称: </span>
								<div class="disease-box">
									<el-input placeholder="输选择疾病" v-model="disInfo.inquiry_info.diagnosis" @input="bedchange"></el-input>
									<div class="disease-list" v-if="state">
										<span class="disease-name" v-for="item in newoptions" :key="item.code" @click="bindvalue(item.name,item.code)">{{item.name}}</span>
									</div>
								</div>
							</div>
							<div class="opinion">
								<p>诊断意见:</p>
								<textarea v-model="disInfo.inquiry_info.reportTxt" cols="30" rows="5"></textarea>
							</div>
						</div>
					</div>
					<div class="drugs">
						<div class="title">
							<span class="shu"></span>
							<div class="fr">
								<span @click="open('addGoodDialog')" class="addcargo">添加药品</span>
							</div>
						</div>
						<div class="drugInfo">
							<el-row :gutter="24" v-for="(value, index) in addGoods" :key="index">
								<el-col :span="22">
									<div class="grid-content bg-purple left">
										<!-- 关闭图片 -->
										<img src="@/assets/image/Popup_Close_icon@2x.png" @click="cancelGood(value)" alt />
										<!-- 名称 -->
										<div class="name">
											<img :src="value.goods_img" alt />
											<span>{{ value.goods_name }}</span>
											<el-input-number class="number" size="mini" :min="1" :max="100" v-model="value.num" @change="handleChange"
											 label="描述文字"></el-input-number>
										</div>
										<div class="instructions">
											<!-- 左侧 -->
											<div class="usage">
												<div class="inputbox">
													<label>医嘱类型</label>
													<div>
														<el-select v-model="value.orderCode" placeholder="请选择" @change="getCode(value.orderCode,exhortlist,index,'orderClassification')">
															<el-option v-for="item in exhortlist" :key="item.code" :label="item.name" :value="item.name">
															</el-option>
														</el-select>
													</div>
												</div>
												<div class="inputbox">
													<label>单次计量</label>
													<div>
														<el-input v-model="value.drugDosage" placeholder="请输入内容"></el-input>
													</div>
												</div>
												<div class="inputbox">
													<label>计量单位</label>
													<div>
														<el-select v-model="value.drugUseUnit_name" placeholder="请选择" @change="getCode(value.drugUseUnit_name,unitlist,index,'drugUseUnit')">
															<el-option v-for="item in unitlist" :key="item.code" :label="item.name" :value="item.name">
															</el-option>
														</el-select>
													</div>
												</div>
												<div class="inputbox">
													<label>用药途径</label>
													<div>
														<el-select v-model="value.drugRoute_name" placeholder="请选择" @change="getCode(value.drugRoute_name,channellist,index,'drugRoute')">
															<el-option v-for="item in channellist" :key="item.code" :label="item.name" :value="item.name">
															</el-option>
														</el-select>
													</div>
												</div>
											</div>
											<!-- 右侧 -->
											<div class="usage">
												<div class="inputbox">
													<label>用药频率</label>
													<div>
														<el-select v-model="value.drugFrequency_name" placeholder="请选择" @change="getCode(value.drugFrequency_name,ratelist,index,'drugFrequency')">
															<el-option v-for="item in ratelist" :key="item.code" :label="item.name" :value="item.name">
															</el-option>
														</el-select>
													</div>
												</div>
												<div class="inputbox">
													<label>总剂量</label>
													<div>
														<el-input v-model="value.drugIntegraldose" placeholder="请输入内容"></el-input>
													</div>
												</div>
												<div class="inputbox">
													<label>用药天数</label>
													<div>
														<el-input v-model="value.drugDay" placeholder="请输入内容"></el-input>
													</div>
												</div>
												<div class="inputbox">
													<label>是否皮试</label>
													<div>
														<el-select v-model="value.skinTest" placeholder="请选择" @change="getCode(value.skin,skinlsit,index,'skinTest')">
															<el-option v-for="(item,index) in skinlsit" :key="item.code" :label="item.name" :value="item.code">
															</el-option>
														</el-select>
													</div>
												</div>
											</div>
										</div>

									</div>
								</el-col>
							</el-row>
						</div>
					</div>

					<div class="case-sign">
						<p>医生签字:</p>
						<div class="doctorName">
							<!-- 签名 -->
							<div class="botCot">
								<div v-if="imgUrl==''" @click="open('showGraph')">
									<div class="graph">
										<span>点击签名</span>
									</div>
								</div>
								<div v-if="imgUrl!=''">
									<div class="mask" @click="clearAutograph">
										<img :src="imgUrl" alt />
										<span>点击清除签名</span>
									</div>
								</div>
								<button @click="open('isErrorLess')">发送至药师审核</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div @mousewheel.prevent v-show="isShow" class="Mask">
				<!-- 添加商品 -->
				<div v-show="addGoodDialog" class="addGoodDialog">
					<div class="sel">
						<span>添加商品</span>
						<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('addGoodDialog')" />
					</div>
					<div class="content" v-if="drugItems">
						<div>
							<div class="jibie">
								<span>一级药品分类</span>
								<el-select v-model="class1value" placeholder="请选择" @change="changeTwoItems">
									<el-option v-for="(item,index) in drugItems" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="jibie">
								<span>二级药品分类</span>
								<el-select v-model="class2value" placeholder="请选择" @change="changeThreeItems">
									<el-option v-for="(item,index) in towDrugItems" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="jibie">
								<span>三级药品分类</span>
								<el-select v-model="class3value" placeholder="请选择" v-el-select-loadmore="loadmore">
									<el-option v-for="(item,index) in threeDrugItems" :key="item.value" :label="item.label" :value="item.value">

									</el-option>
								</el-select>
							</div>
						</div>
						<div class="zhuji">
							<i class="el-icon-search"></i>
							<input type="text" placeholder="请输入助记码" />
						</div>
						<div class="name">
							<div>
								<el-radio v-model="radio" label="1">
									<span>x1 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="2">
									<span>x2 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="3">
									<span>x3 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="4">
									<span>x4 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="5">
									<span>x5 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="6">
									<span>x6 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="7">
									<span>x7 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="8">
									<span>x8 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="9">
									<span>x9 （袋 / 盒）</span>
								</el-radio>
							</div>
							<div>
								<el-radio v-model="radio" label="10">
									<span>x10 （袋 / 盒）</span>
								</el-radio>
							</div>
						</div>
					</div>
					<button @click="sendAddGood">确认添加</button>
				</div>
				<!-- 签名功能 -->
				<div v-show="showGraph" class="autograph">
					<div class="sel">
						<span>医生签名</span>
						<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
					</div>
					<div class="content">
						<Autograph @getImgUrl="getImgUrl" />
					</div>
				</div>
				<!-- 确认处方单无误 -->
				<div v-show="isErrorLess" class="isErrorLess">
					<div class="sel">
						<span>提示</span>
						<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('isErrorLess')" />
					</div>
					<div class="content">确认处方单信息无误?</div>
					<button @click="close('isErrorLess')">否</button>
					<button @click="sendPharmacistExamine">是</button>
				</div>
			</div>
		</div>
		<div v-else class="isImage">
			<div class="uploadbox">
				<img :src="disInfo.pic" >
				<input ref="upload_img" title="点击上传新的图片" @change="OpenUpload" type="file" id="test-image-file" name="test" accept="image/gif, image/jpeg, image/png, image/jpg">
			</div>
			<el-button type="success" @click="Select">确认修改</el-button>
		</div>
		<div class="doclist" v-show="pharmacist">
			<div class="doctitle">选择药师 <span class="el-icon-close" @click="pharmacist=false"></span></div>
			<div class="peoples">
				<div class="peobox" v-for="(item,index) in doclists" :key="index"  @click="opendoc(item.uid)">
					<div class="head">
						<img :src="item.avatar" alt="">
					</div>
					<span>{{item.user_login}}</span>
				</div>
			</div>
			<div class="docbottom"><span class="el-icon-d-arrow-right"></span></div>
		</div>
	</div>
</template>

<script>
	import Autograph from "@/components/autograph";

	// 获取药品列表
	import {
		getAllCate
	} from "@/request/personnel";
	// 获取药品详细信息
	import {
		getCateIndex
	} from "@/request/personnel";
	// 获取处方单详情
	import {
		getInfo
	} from "@/request/personnel";
	// 发送至药师审核
	import {
		inquiryAdd
	} from "@/request/personnel";
	import {
		getBedInfor
	} from "@/request/personnel";
	import {
		getcaseInfo
	} from "@/request/personnel"; // 处方单详情
	export default {
		props: ["pCode"],
		components: {
			Autograph
		},
		data() {
			return {
				newoptions: [],
				options: [],
				value: "",
				isShow: false,
				addGoodDialog: false,
				autograph: false,
				isErrorLess: false,
				drugItems: [], // 药品各级分类
				towDrugItems: [],
				threeDrugItems:[],
				imgUrl: "", // 签名图片URl
				isAutograph: false, // 是否签名
				showGraph: false, // 签名弹框
				addGoods: [], // 添加的药品列表, 即药品存储对象
				num: 1,
				radio: 0,
				diagResult: "",
				disInfo: "", // 处方详细信息
				state: false,
				exhortlist: [],
				unitlist: [],
				channellist: [],
				ratelist: [],
				skinlsit: [{
					code: "0",
					name: "不需要皮试"
				}, {
					code: "1",
					name: "需要皮试"
				}], //是否皮试
				skin: "",
				class1: "",
				class1value: "",
				class2value: "",
				class3value: "",
				page: 1,
				class3goodlist: [],
				avatar:"",
				pharmacist:false,
				doclists:"",
			};
		},
		filters: {
			formatTime: function(value) {
				let time;
				var times = new Date(value * 1000);
				let y = times.getFullYear();
				let M = times.getMonth() + 1;
				let d = times.getDate();
				let h = times.getHours();
				let m = times.getMinutes();
				let s = times.getSeconds();
				M = M >= 10 ? M : ('0' + M);
				d = d >= 10 ? d : ('0' + d);
				h = h >= 10 ? h : ('0' + h);
				m = m >= 10 ? m : ('0' + m);
				s = s >= 10 ? s : ('0' + s);
				time = y + "-" + M + "-" + d + "  " + h + ":" + m + ":" + s;
				return time;
			}
		},
		created() {
			getBedInfor({
				table: "ICD10"
			}).then(res => {
				this.options = res.data;
			});
			// 医嘱项目分类
			getBedInfor({
				table: "ORDERCLASSIFICATION"
			}).then(res => {
				this.exhortlist = res.data;
			});
			//药品单位
			getBedInfor({
				table: "DRUGDOSAGEUNIT"
			}).then(res => {
				this.unitlist = res.data;
			});
			//用药途径
			getBedInfor({
				table: "DRUGROUTE"
			}).then(res => {
				console.log(res);
				this.channellist = res.data;
			});
			getBedInfor({
				table: "DRUGFREQUENCY"
			}).then(res => {
				this.ratelist = res.data;
			});
			// 获取1级分类
			getAllCate().then(res => {
				this.class1 = res.data;
				for (let i in res.data) {
					this.drugItems.push({
						value: i,
						label: res.data[i].category_name,
					})
				}
				console.log(1111);
			});
			getInfo({
				token: this.$token,
				p_code: this.pCode
			}).then(res => {
				console.log(res);
				this.disInfo = res.data;
				this.addGoods = this.disInfo.goods_list; // 药品对象
			});
		},
		directives: {
			'el-select-loadmore': {
				inserted(el, binding) {
					const SELECTWRAP_DOM = el.querySelector(
						'.el-select-dropdown .el-select-dropdown__wrap'
					);
					SELECTWRAP_DOM.addEventListener('scroll', function() {
						const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
						if (condition) {
							binding.value();
						}
					});
				}
			}
		},
		methods: {
			// 类型
			getCode(name, list, index, str) {
				for (let i in list) {
					if (list[i].name == name) {
						this.addGoods[index][str] = list[i].code
					}
				}
			},
			opendoc(id) {
				this.pharmacist = false;
				this.unloadimg = false;
				this.$confirm('发送给该药师?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.sendPharmacist(id)
				}).catch(() => {});
			},
			sendPharmacist(id){
				this.$axios.post("/api/prescription/doctorAdd",{
					pic:this.disInfo.pic,
					yaoshi_uid:id,
					token:this.$token,
					p_code:this.disInfo.p_code
				}).then(res=>{
					if(res.data.code==1){
						this.$message.success("上传成功");
					}
				});
			},
			// 选择药师
			Select() {
					this.pharmacist = true;
					this.$axios.post("/api/doctor/getOnlineList", {
						cert_type: 3,
						page_size: 1000,
						token: this.$token,
					}).then(res => {
						this.doclists = res.data.data.data;
					})
			},
			// 选择图片
			OpenUpload(e) {
				console.log(e);
				let files = e.target.files[0];
				this.formData = new FormData();
				this.formData.append("file", files);
				this.formData.append("token", this.$token);
				let url = "";
				let reader = new FileReader();
				reader.readAsDataURL(files)
				let that = this;
				reader.onload = function() {
					url = this.result.substring(this.result.indexOf(",") + 1);
					that.upload_imgurl = "data:image/png;base64," + url;
					console.log(that.upload_imgurl);
					that.upload_file();
				}
			},
			// 上传
			upload_file() {
				this.$axios.post("/api/file/upload", this.formData).then(res => {
					if (res.data.code == 1) {
						this.disInfo.pic=res.data.data.file.url;
					}
				});
			
			},
			bindvalue(name, code) {
				this.value = name;
				this.disInfo.inquiry_info.diagnosis = name;
				this.disInfo.inquiry_info.diagCode = code;
				this.state = false;
			},
			bedchange() {
				this.newoptions = [];
				if (this.value != "") {
					for (let i in this.options) {
						if (this.options[i].name.indexOf(this.value) != -1) {
							this.newoptions.push(this.options[i]);
						}
						if (i == this.options.length - 1) {
							this.state = true;
							console.log(this.state)
						}
					}

				} else {
					this.state = false;
				}

			},
			close(e) {
				this[e] = false;
				this.isShow = false;
			},
			open(e) {
				this.isShow = true;
				this[e] = true;
			},
			handleChange(num) {
				// 药品数量变化时触发
				this.num = num;
			},
			getImgUrl(imgUrl) {
				// 获取签名图片并保存
				this.imgUrl = imgUrl;
				this.isShow = false;
				this.showGraph = false;
				this.isAutograph = true;
			},
			sendAddGood() {
				// 医生添加的商品
				// 从第三级数组中筛选出要选择的药品
		let result = this.class3goodlist.find(
			item => item.id === this.class3value
		);
		console.log(result);
						let flag=false;
						for(let i of this.addGoods){
							if(i.goods_id==result.id){
								flag=true;
							}else{
								flag=false;
							}
						}
				// 阻止重复添加同一商品
				if (flag) {
					this.$message({
						type: "error",
						message: "商品已经存在"
					});
				} else {
					this.addGoods.push({
						goods_name: result.goods_name,
						goods_img: result.goods_img,
						num: +this.radio,
						goods_id: result.id,
						unit:result.unit,
					});
					this.num = this.radio;
				}
				this.isShow = false;
				this.addGoodDialog = false;
			},
			cancelGood(good) {
				// 取消添加商品
				let index = this.addGoods.indexOf(good);
				if (index != -1) this.addGoods.splice(index, index + 1);
			},
			// 二级分类
			changeTwoItems(v) {
				this.towDrugItems = [];
				this.threeDrugItems = [];
				this.class2value = "";
				this.class3value = "";
				for (let i of this.class1[v].childData) {
					this.towDrugItems.push({
						value: i.id,
						label: i.category_name,
					})
				}
				console.log(this.threeDrugItems);
			},
			// 三级分类
			changeThreeItems(v) {
				this.page = 1;
				this.goodid = v;
				this.class3value = "";
				this.threeDrugItems = [];
				this.class3goodlist = [];
				getCateIndex({
					category_id: v,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			// 三级下拉加载
			loadmore() {
				getCateIndex({
					category_id: this.goodid,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			clearAutograph() {
				// 清除签名
				this.imgUrl = "";
				this.showGraph = true;
			},
			sendPharmacistExamine() {
				if (this.imgUrl == "") {
					this.$message({
						type: "error",
						message: "请签名后再提交"
					});
					return;
				}
				// 发送至药师审核
				let data = {
					token: this.$token,
					inquiry_id: this.disInfo.inquiry_info.inquiry_id,
					goods_list_json: this.addGoods,
					inquiry_patient_json: {
						...this.disInfo.inquiry_info
					},
					audit_sign: this.imgUrl
				};
				inquiryAdd(data).then(res => {
					if (res.code != 0) {
						this.$message({
							type: "success",
							message: "发送成功"
						});
						this.isShow = false;
						this.isErrorLess = false;
						// 跳转
						this.$emit("changeState", 1);
					}
					console.log(res);
				});

			}
		}
	};
</script>

<style lang="less" scoped>
	.doclist {
		width: 200px;
		background-color: #fff;
		position: fixed;
		left: 50%;
		top: 46%;
		padding-bottom: 20px;
		box-shadow: 0px 0px 5px #CCCCCC;
		transform: translate(-50%, -50%);
		z-index: 999999;
	
		.doctitle {
			text-align: center;
			line-height: 30px;
			position: fixed;
			top: 0px;
			width: 100%;
			background-color: #fff;
			border-bottom: 1px solid #EAEAEA;
			font-size: 14px;
	
			span {
				position: absolute;
				right: 10px;
				top: 7px;
			}
		}
	
		.peoples::-webkit-scrollbar {
			display: none
		}
	
		.peoples {
			margin-top: 30px;
			height: 200px;
			overflow: auto;
	
			.peobox:hover {
				background-color: #F0F0F0;
			}
	
			.peobox {
				display: flex;
				border-bottom: 1px solid #F0F0F0;
				padding: 5px;
				cursor: pointer;
	
				span {
					display: inline-block;
					margin-left: 10px;
					line-height: 40px;
				}
	
				.head {
					border-radius: 100%;
					width: 40px;
					height: 40px;
					overflow: hidden;
	
					img {
						width: 40px;
						height: 40px;
						display: inline-block;
					}
	
				}
			}
		}
	
		.docbottom {
			position: absolute;
			bottom: 0;
			width: 100%;
			// height: 20px;
			text-align: center;
	
			// background-color: black;
			span {
				transform: rotate(90deg);
			}
		}
	}
	.isImage{
		/deep/.el-button{
			display: block;
			margin: 30px auto;
			
		}
	}
	.uploadbox{
		width: 500px;
		height: 300px;

		margin: 20px auto;
		position: relative;
		img{
			width: 100%;
			height: 100%;
		}
		input{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: red;
			opacity: 0;
		}
	}
	.title .addcargo {
		color: #4EEEC8;
		cursor: pointer;
	}

	.botCot {
		width: 100%;
		border-top: 1px solid #38eec1;
		height: 140px;
		position: absolute;
		bottom: 0px;
		left: 0px;

		>div {
			width: 210px;
			height: 105px;
			position: absolute;
			top: 30px;
			left: 10px;

			.mask {
				position: absolute;
				left: 0px;
				top: 0px;
				width: 210px;
				height: 105px;
				background: rgba(0, 0, 0, 0.5);
				line-height: 110px;
				text-align: center;
				color: #fff;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
				}

				span {
					position: absolute;
					top: 0px;
					color: #fff;
					left: 50px;
				}
			}

			.graph {
				box-sizing: border-box;
				border: 2px solid #ccc;
				height: 100%;
				text-align: center;
				cursor: pointer;

				span {
					display: inline-block;
					margin-top: 40px;
				}
			}
		}

		button {
			padding: 10px 35px;
			background: #38eec1;
			border-radius: 12px;
			position: absolute;
			top: 100px;
			margin-left: 240px;
			cursor: pointer;
		}
	}

	.doctorName {
		height: 140px;
		width: 100%;
		position: relative;
	}

	.usage .inputbox {
		display: flex;
		margin-bottom: 10px;

		label {
			width: 60px;
			line-height: 24px;
		}

		div {
			width: 130px;
			margin-left: 5px;
		}

		/deep/.el-input__inner {
			height: 24px;
		}

		/deep/.el-input__icon {
			line-height: 20px;
		}

	}

	.instructions {
		display: flex;
		justify-content: space-between;
	}

	.drugs .title {
		height: 40px;
		line-height: 40px;
	}

	.name {
		border-top: 20px solid #ddd;
		vertical-align: middle;

		span {
			margin-left: 12px;
			width: 200px;
		}
	}

	.drugInfo {
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 14px;
		height: 240px;

		>.el-row {
			position: relative;
			padding-bottom: 10px;
			border-bottom: 1px solid #eee;
			margin-left: 5px !important;

			.grid-content.bg-purple {
				>img {
					position: absolute;
					right: 30px;
					top: 20px;
					right: 25px;
					cursor: pointer;
				}
			}
		}

		.el-input-number {
			margin-top: 5px;
			float: right;
		}

		.explain {
			margin: 0 12px;
		}

		.el-input {
			display: inline-block;
		}

		.title {
			margin-top: 0px;
			margin-bottom: 20px;
		}

		.grid-content.bg-purple.left>div {
			margin-top: 10px;
			margin-left: 8px;
		}

		.drugInfo .el-row:nth-child(1) {
			line-height: 40px;
		}

		.grid-content.bg-purple.num span {
			margin-left: 50px;
		}

		.grid-content.bg-purple.del {
			color: red;
			cursor: pointer;
		}

		.name {
			border: 1px solid #f5f5f5;

			>span {
				width: 50%;
				display: inline-block;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			img {
				vertical-align: -10px;
				margin-right: 15px;
				width: 40px;
				height: 40px;
			}
		}
	}

	.disease-change {
		display: flex;

		span {
			display: block;
			line-height: 40px;
			margin-right: 10px;
		}
	}

	.opinion {
		textarea {
			width: 100%;
			font-family: "微软雅黑";
			font-size: 16px;
			resize: none;
		}
	}

	.case-box {
		width: 800px;
		min-width: 800px;
		margin: 0 auto;
		background: #fff;
		padding: 30px 40px;
	}

	.case-box .content {
		padding: 20px 40px;
		border-bottom: 2px solid #a8a8a8;
	}

	.case-sign {
		padding: 20px 40px;

		p {
			margin-bottom: 10px;
		}
	}

	.case-sign img {
		width: 150px;
		height: 150px;
	}

	.case-box .case-info {
		background: #f5f5f5;
		padding: 40px 100px;
	}

	.case-box .case-title {
		text-align: center;

		h4 {
			margin-bottom: 10px;
		}
	}

	.case-top-info,
	.case-content {
		width: 100%;
		padding: 0 40px;
		display: flex;
		justify-content: space-between;

		p {
			margin: 10px 0;

			span {
				color: #8f8f8f;
			}
		}
	}

	.case-top,
	.case-content {
		padding: 20px 0px;
		border-bottom: 2px solid #a8a8a8;
	}

	.case-top .case-img {
		padding: 0 40px;

		span {
			display: block;
			margin-bottom: 10px;
		}

		img {
			display: block;
			width: 150px;
			height: 150px;
		}
	}

	.case-content {
		padding: 20px 40px;
	}

	.evaluate .btn /deep/ {
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translateY(-90%);

		.el-button--primary {
			margin-right: 20px;
		}
	}

	.pre {
		width: 1200px;
		margin: 0 auto;
		min-height: 830px;
		margin-top: 10px;
		font-size: 16px;
	}

	.wrap {
		background: #fff;
	}

	.pre>p,
	.details-wrap>p {
		background: #fff;
		font-size: 20px;
		color: #000;
		height: 70px;
		border: 1px solid #ccc;
		border-bottom: 0px;
		line-height: 70px;
		padding-left: 15px;
	}

	.details-wrap>p {
		color: #4eeec8;
	}

	.evaluate:after {
		content: "";
		display: block;
		clear: both;
	}

	.wrap {
		min-height: 690px;
		padding-bottom: 40px;
		border: 1px solid #ccc;
	}

	.page {
		text-align: center;
		margin: 30px 0px 0px 0px;
	}

	.evaluate {
		position: relative;
		font-size: 14px;
		margin-top: 40px;
		padding-left: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
	}

	.evaluate .fr {
		float: left;

		span {
			margin-right: 8px;
		}
	}

	.evaluate .fr p {
		color: #555;
		font-size: 12px;
		height: 30px;
		margin-left: 30px;
	}

	.evaluate .fl img {
		width: 80px;
		height: 80px;
	}

	.details span {
		color: #4eeec8;
		cursor: pointer;
	}

	.details-wrap {
		.wrap {
			section {
				position: relative;
				overflow: hidden;
				border-bottom: 1px solid #ccc;

				button {
					padding: 6px 20px;
					background: #fff;
					color: #4eeec8;
					border: 1px solid #4eeec8;
					position: absolute;
					right: 60px;
					bottom: 20px;
					cursor: pointer;
				}
			}

			.title {
				padding-top: 30px;

				img {
					margin-left: 30px;
					width: 100px;
					height: 100px;
				}

				dl {
					display: inline-block;
					margin-left: 30px;

					dd {
						margin-bottom: 14px;

						span {
							margin-right: 6px;
						}
					}

					dd:nth-child(1) {
						span {
							margin-right: 20px;
						}
					}
				}

				width: 800px;
				height: 160px;

				.name {
					color: #4eeec8;
				}
			}

			.content {
				padding-top: 15px;
				height: 45px;
				line-height: 45px;
				font-size: 18px;

				span {
					display: inline-block;
					width: 120px;
				}

				span:nth-child(1) {
					margin-left: 50px;
					color: #313131;
				}

				span:nth-child(2) {
					color: #a8a8a8;
					width: 900px;
				}
			}
		}
	}

	.disponseInfo {
		width: 1200px;
		color: #333;
		margin: 0 auto;
		border: 1px solid #ccc;
		padding: 100px 0px;
		background: #fff;
	}

	.disponseInfo>div {
		padding: 30px;
	}

	// 单据信息
	.disponseInfo .bill {
		box-sizing: border-box;
		background: #f5f5f5;
		margin-left: 200px;
		box-shadow: 5px 5px 5px #ccc;
		width: 600px;
		min-height: 744px;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
		}

		h3 {
			font-size: 28px;
			font-weight: 500;
			text-align: center;
			margin: 10px 0px;
		}

		>.el-row {
			border-bottom: 1px solid #424242;
			line-height: 40px;
		}

		.content {
			min-height: 100px;
			padding: 10px 0px 15px 0px;

			.el-row {
				border: none;
				padding: 3px 0;

				.el-col {
					margin: 3px 0;
				}

				.el-col:nth-child(2) {
					text-align: center;
				}

				.el-col:nth-child(4) {
					text-indent: 2em;
				}

				.el-col:nth-child(5) {
					text-align: center;
				}

				.el-col:nth-child(3) {
					text-align: right;
				}

				.el-col:nth-child(6) {
					text-align: right;
				}

				.el-col {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.type {
				font-size: 22px;
				font-weight: 700;
			}

			.name {
				margin: 3px 0;
			}
		}

		.graph {
			border-top: 1px solid #424242;
			height: 150px;

			.el-row {
				margin-top: 12px;

				img {
					width: 100%;
				}
			}
		}
	}

	// .disponseInfo .autograph {
	// 	height: 100px;
	// 	line-height: 100px;
	// 	text-align: center;
	// 	font-size: 13px;
	// 	color: #999;
	// 	margin-left: -25px;
	// 	cursor: pointer;
	// }

	.disponseInfo div.wrap_autograph {
		width: 800px;
		margin-top: 15px;

		h3 {
			margin-bottom: 15px;
			font-weight: 500;
			margin-left: 20px;
		}

		textarea {
			margin-bottom: 10px;
			font-size: 18px;
			padding: 8px;
		}
	}

	.disponseInfo div.wrap_autograph div {
		text-align: center;
	}

	.el-button.el-button--primary {
		background-color: #38eec1;
		border: #38eec1;
	}

	.autograph {
		width: 600px;
		height: 370px;
		padding: 12px;
		position: fixed;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		background: #fff;
		z-index: 5;

		div {
			img {
				width: 15px;
				height: 15px;
				float: right;
				cursor: pointer;
			}
		}

		.content {
			margin: 20px 20px 20px;
			height: 240px;
			border: 1px solid #ccc;
		}
	}

	.addGoodDialog {
		width: 600px;
		height: 80%;
		padding: 12px;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		z-index: 5;
		overflow: auto;
		div {
			img {
				width: 15px;
				height: 15px;
				float: right;
				cursor: pointer;
			}
		}

		.content {
			padding: 40px 20px 20px;

			.jibie {
				height: 35px;

				select {
					height: 27px;
					width: 400px;
					margin-left: 14px;
				}
			}

			.zhuji {
				i {
					margin-top: 10px;
					margin-right: 14px;
				}
			}

			.name {
				border-top: 20px solid #ddd;
				margin-top: 20px;
				padding: 10px;

				span {
					margin-left: 12px;
				}
			}
		}

		button {
			position: relative;
			padding: 5px 25px;
			background: #fff;
			color: red;
			border: 1px solid red;
			border-radius: 12px;
			bottom: 20px;
			outline: 0;
			left: 50%;
			transform: translateX(-50%);

			cursor: pointer;
		}
	}

	.isErrorLess {
		width: 460px;
		height: 260px;
		padding: 12px;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 5;
		background: #fff;

		div {
			img {
				width: 15px;
				height: 15px;
				float: right;
				cursor: pointer;
			}
		}

		.content {
			padding: 60px 20px 20px;
			font-size: 24px;
			text-align: center;
		}

		button:nth-of-type(1) {
			padding: 5px 25px;
			background: #fff;
			color: red;
			border: 1px solid red;
			border-radius: 12px;
			position: absolute;
			bottom: 20px;
			outline: 0;
			left: 150px;
			cursor: pointer;
		}

		button:nth-of-type(2) {
			padding: 5px 25px;
			background: #f00000;
			color: #fff;
			border: 1px solid red;
			border-radius: 12px;
			position: absolute;
			bottom: 20px;
			outline: 0;
			left: 260px;
			cursor: pointer;
		}
	}
</style>
