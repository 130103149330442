<template>
	<div>
		<Header v-if="isLandHeader" />
		<template v-if="isLandHeader">
			<LandHeader nameType="医生端" @changeState="changeState" />
			<div class="banner" v-if="state === 0">
				<!-- <img v-if="banner_List.length" :src="banner_List[0].image" alt /> -->
				<el-carousel height="512px">
					<el-carousel-item v-for="(item,index) in banner_List" :key="index">
						<img :src="item.image" alt="">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="wrap">
				<!-- 工作台 -->
				<Workbench v-if="state === 0" :update_pre_num="update_pre_num" :chat_type="chat_type" @changeOnline="changeOnline"
				 @changeState="changeState" @acceptInquiry="acceptInquiry" />
				<!-- 药师审方 -->
				<UpdatePre v-if="state === 1" @changeState="changeState" />
				<!-- 药师审方 > 处方信息 -->
				<DisposeInfo v-if="state === 2" :pCode="pCode" @changeState="changeState" />
				<!-- 医生待修改处方信息 -->
				<ModifyPre v-if="state === 3" :pCode="pCode" @changeState="changeState" />
			</div>
			<Footer />
		</template>
		<template v-else>
			<OnlineInquiry :userMessage="userMessage" :inquiry_Info="inquiryInfo" :inquiryName="inquiryName" :patientList="patientList"
			 :chat_type="chat_type" @changeOnline="changeOnline" @sendMessage="sendMessage" />

		</template>
	</div>
</template>

<script>
	import Header from "@/components/home_repect/header-top.vue";
	import Footer from "@/components/public/public_bottom.vue";
	import LandHeader from "@/components/home_repect/header.vue";
	import Workbench from "./workbench";
	import UpdatePre from "./updatePre";
	import DisposeInfo from "./disposeInfo";
	import OnlineInquiry from "./online_inquiry/main";
	import ModifyPre from "./modifyPre";

	// 获取用户信息
	import {
		usergetinfo
	} from "@/request/user";
	// 获取自己添加的问诊处方单列表
	import {
		getPrescriptionList
	} from "@/request/personnel";
	// 获取轮播列表
	import {
		banner
	} from "@/request/index";
	// 获取患者档案列表
	import {
		getPatientList
	} from "@/request/personnel";

	import Vue from "vue";
	export default {
		components: {
			Header,
			Footer,
			LandHeader,
			Workbench,
			UpdatePre,
			DisposeInfo,
			OnlineInquiry,
			ModifyPre
		},
		data() {
			return {
				state: 0,
				isLandHeader: true,
				banner_List: "", // 轮播图片列表
				userMessage: {},
				update_pre_num: 0, // 待修改审方数
				pCode: "",
				inquiryInfo: "", //
				userinfo: "", // 医生个人信息
				inquiryName: "",
				isAcceptInquiry: true, // 是否接受问诊
				reason: "", // 拒绝问诊原因
				patientList: [],
				chat_type: 0,
				inquiry_doctor_id: "",
				inquiry_id: "",
				patient_uid: ""
			};
		},
		created() {
			Vue.prototype.$token = localStorage.getItem("lzyyToken");

			// 医生进入首页就连接socket
			if (!this.$store.state.isLogin) {
				this.initWebSocket();
			} else {
				this.websocketsend = this.$store.state.sendMessage;
				this.$store.state.websock.onmessage = this.websocketonmessage;
			}

			// 获取个人信息
			usergetinfo({
				token: this.$token
			}).then(res => {
				this.userinfo = res.data;
				Vue.prototype.$userinfo = this.userinfo;
			});

			// 获取图片列表
			banner({
				position_id: 1
			}).then(res => {

				this.banner_List = res.data;
			});

			this.getPreList();
		},
		destroyed() {
			// 离开首页关闭socket
			// this.websocketclose();
		},
		methods: {
			changeState(num, pCode) {
				this.state = num;
				// 需要把处方单详情给传递过去
				if (num == 2 || num == 3) {
					this.pCode = pCode;
				}
			},
			getPreList() {
				// 获取自己添加的问诊处方单信息
				getPrescriptionList({
					token: this.$token,
					status_str: "3" // 包括待审核、医生已签字、药师已拒绝
				}).then(res => {
					this.update_pre_num = res.data.data.length;
				});
			},
			changeOnline(e, reason) {
				// 结束了问诊
				if (e == "finish") {
					this.finishInquiry();
					this.getPreList();
					this.isLandHeader = true;
					return;
				}
				if (e) {
					// 接受了问诊
					this.isAcceptInquiry = true;
					this.isLandHeader = false;
					this.acceptInquiry();
				} else {
					// 拒绝了问诊
					this.reason = reason;
					this.isAcceptInquiry = false;
					this.refuseInquiry();
				}
			},
			initWebSocket() {
				//初始化websocket
				// const wsuri = "wss://apponline.sdlzyy.cn/wss";
				const wsuri = "wss://hlwyaodian.jzbwlkj.com/wss"
				this.websock = new WebSocket(wsuri);
				// onopen 建立连接成功后执行的方法
				this.websock.onopen = this.websocketonopen;
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;
				this.$store.state.sendMessage = this.websocketsend;
				this.$store.state.websock = this.websock;
				// 心跳
				setInterval(() => {
					this.websocketsend({
						method: "ping"
					});
				}, 10000);
			},
			websocketonopen() {
				// 连接建立之后执行send方法发送数据
				// this.websocketsend();
				// this.userlogin();
			},
			websocketonerror() {
				// 连接建立失败重连
				this.initWebSocket();
			},
			websocketonmessage(e) {
				// 数据接收
				const redata = JSON.parse(e.data);
				console.log(redata);
				// 更新等待人数
				if (redata.result) {
					this.$store.state.waiting_num = redata.result.waiting_num;
				}
				if (redata.method == "sys") {
					this.userlogin();
					// 获取问诊患者的详细信息
					setInterval(() => {
						this.getInquiryInfo();
					}, 3000);
					this.$store.state.isLogin = true;
				}

				if (redata.method == "get_waiting") {
					this.inquiryInfo = redata.result.inquiry_info;
					this.chat_type = this.inquiryInfo.chat_type
				}

				if (redata.method == "doctor_connect") {
					if (redata.result.chat_status == 5) return;
					this.getInquiryDtails();
					this.chatIn();
				}

				// 消息传递过来，且不是空信息。 信息筛选
				if (redata.method == "chat_message" || redata.method == "chat_in"||redata.method=="chat_close") {
					this.selectInfo(redata);
				}
			},
			userlogin() {
				let actions = {
					method: "login",
					token: this.$token
				};
				this.websocketsend(JSON.stringify(actions));
			},
			getInquiryInfo() {
				// 获取待问诊患者的详细信息
				let getWaiting = {
					method: "get_waiting"
				};
				this.websocketsend(JSON.stringify(getWaiting));
			},
			acceptInquiry() {
				// 医生接受问诊时触发
				this.inquiry_doctor_id = this.inquiryInfo.inquiry_doctor_id;
				this.inquiry_id = this.inquiryInfo.id;
				this.patient_uid = this.inquiryInfo.patient_uid;
				let set_inquiry = {
					method: "set_inquiry",
					data: {
						inquiry_doctor_id: this.inquiry_doctor_id, // 医生问诊记录id
						chat_status: 2
					}
				};
				this.websocketsend(JSON.stringify(set_inquiry));
			},
			refuseInquiry() {
				// 拒绝问诊。
				let set_inquiry = {
					method: "set_inquiry",
					data: {
						inquiry_doctor_id: this.inquiryInfo.inquiry_doctor_id,
						chat_status: 5,
						remark: this.reason
					}
				};
				this.websocketsend(JSON.stringify(set_inquiry));
			},
			chatIn() {
				// 医生进入聊天室，紧接着触发
				let chat_in = {
					method: "chat_in",
					data: {
						inquiry_doctor_id: this.inquiry_doctor_id,
						inquiry_id: this.inquiry_id
					}
				};
				this.websocketsend(JSON.stringify(chat_in));
			},
			getInquiryDtails() {
				getPatientList({
					inquiry_id: this.inquiry_id,
					token: this.$token
				}).then(res => {
					this.patientList = res.data.data;
				});
			},
			sendMessage(msg) {
				// 代理子组件发送消息
				let chat_message = {};
				if (msg.includes(".jpg") || msg.includes(".png")) {
					chat_message = {
						method: "chat_message",
						data: {
							inquiry_id: this.inquiry_id,
							inquiry_doctor_id: this.inquiry_doctor_id,
							to_uid: this.patient_uid,
							send_img: msg
						}
					};
				} else if (msg.includes(".mp4")) {
					chat_message = {
						method: "chat_message",
						data: {
							inquiry_id: this.inquiry_id,
							inquiry_doctor_id: this.inquiry_doctor_id,
							to_uid: this.patient_uid,
							send_video: msg
						}
					};
				} else if (msg.includes(".doc") || msg.includes(".ppt") || msg.includes(".pdf") || msg.includes(".xls") || msg.includes(".txt")) {
					chat_message = {
						method: "chat_message",
						data: {
							inquiry_id: this.inquiry_id,
							inquiry_doctor_id: this.inquiry_doctor_id,
							to_uid: this.patient_uid,
							send_file: msg
						}
					};
				} else {
					chat_message = {
						method: "chat_message",
						data: {
							inquiry_id: this.inquiry_id,
							inquiry_doctor_id: this.inquiry_doctor_id,
							to_uid: this.patient_uid,
							send_text: msg
						}
					};
				}
				this.websocketsend(JSON.stringify(chat_message));
			},
			finishInquiry() {
				let endInquiry = {
					method: "chat_close",
					data: {
						inquiry_id: this.inquiry_id,
						inquiry_doctor_id: this.inquiry_doctor_id
					}
				};
				this.websocketsend(JSON.stringify(endInquiry));
			},
			websocketsend(Data) {
				//数据发送
				this.websock.send(Data);
			},
			websocketclose(e) {
				//关闭
				console.log("断开连接", e);
			},
			selectInfo(redata) {
				// 筛选信息
				console.log(redata);
				if (redata.method == "chat_in") {
					if (redata.result.user_info && redata.result.user_info.cert_type == 0) {
						this.userMessage = redata;
						this.inquiryName = redata.result.user_info.username;
					}
				} else {
					if (
						redata.result.user_info.user_id == this.patient_uid ||
						redata.result.message_type == "sys_notice"
					) {
						this.userMessage = redata;
					}
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.banner {
		height: 512px;
		min-width: 1200px;

		img {
			height: 512px;
			width: 100%;
			min-width: 1200px;
		}
	}
</style>
