// 右边栏
<template>
	<div class="rightCot">
		<div class="topCot">
			<!-- 患者信息 -->
			<div class="patInfo">
				<div class="title">
					<span class="shu"></span>
					<span>患者信息</span>
					<div class="fr">
						<span @click="open('selDialog')">选择</span>
						<span @click="open('editDialog')">编辑</span>
					</div>
				</div>
				<div class="content">
					<el-row>
						<el-col :span="11">
							<span class="space">姓名</span>
							<span>{{ inquiryInfo.realname ? inquiryInfo.realname : '无' }}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">性别</span>
							<span>{{ !inquiryInfo.sex ? '无' : inquiryInfo.sex === 1 ? '男' : '女' }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">年龄</span>
							<span v-if="inquiryInfo.age!=undefined">{{ inquiryInfo.age ? inquiryInfo.age : "无"}}</span>
							<span v-else>{{ inquiryInfo.birthday ? inquiryInfo.birthday : "无" |gettime}}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">手机号</span>
							<span>{{ inquiryInfo.mobile ? inquiryInfo.mobile: "无"}}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">体重</span>
							<span>{{ inquiryInfo.weight ? inquiryInfo.weight+"kg": "无"}}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">身高</span>
							<span>{{ inquiryInfo.height ? inquiryInfo.height+"cm": "无"}}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">患者病情</span>
							<span>{{ condition ? condition: "无"}}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">过敏史</span>
							<span>{{ inquiryInfo.allergy ? inquiryInfo.allergy: "无" }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">遗传史</span>
							<span>{{ inquiryInfo.genetic_disease ? inquiryInfo.genetic_disease : "无" }}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">身份证</span>
							<span>{{ inquiryInfo.idcard ? inquiryInfo.idcard : "无" }}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">现住址</span>
							<span>{{ inquiryInfo.address ? inquiryInfo.address : "未填写" }}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">与联系人关系</span>
							<span>{{this.getrelaname(inquiryInfo.relationship)}}</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<span class="space">联系人</span>
							<span>{{ inquiryInfo.linkman ? inquiryInfo.linkman : "未填写" }}</span>
						</el-col>

						<el-col :span="13">
							<span class="space">联系人地址</span>
							<span>{{ inquiryInfo.linkmanAddr ? inquiryInfo.linkmanAddr : "未填写" }}</span>
						</el-col>
					</el-row>
					<el-row>

						<el-col :span="11">
							<span class="space">付费方式</span>
							<span>{{this.getname(inquiryInfo.medicalPayment)}}</span>
						</el-col>
						<el-col :span="13">
							<span class="space">联系人电话</span>
							<span>{{inquiryInfo.linkmanTele?inquiryInfo.linkmanTele:"未填写"}}</span>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- 添加药品 -->
			<div class="drugs">
				<div class="title">
					<span class="shu"></span>
					<span>添加药品</span>
					<div class="fr">
						<span @click="open('addGoodDialog')">添加药品</span>
					</div>
				</div>
				<div v-if="isAddDrug" class="drugInfo">
					<el-row :gutter="24" v-for="(value, index) in addGoods" :key="index">
						<el-col :span="22">
							<div class="grid-content bg-purple left">
								<!-- 关闭图片 -->
								<img src="@/assets/image/Popup_Close_icon@2x.png" @click="cancelGood(value)" alt />
								<!-- 名称 -->
								<div class="name">
									<img :src="value.goods_img" alt />
									<span>{{ value.goods_name }}</span>
									<div class="drugsnumber">
										<el-input-number class="number" size="mini" :min="1" :max="100" v-model="value.num" @change="handleChange"
										 label="描述文字"></el-input-number>
									</div>
								</div>
								<div class="instructions">
									<!-- 左侧 -->
									<div class="usage">
										<div class="inputbox">
											<label>医嘱类型</label>
											<div>
												<el-select v-model="value.orderCode" placeholder="请选择" @change="getCode(value.orderCode,exhortlist,index,'orderClassification')">
													<el-option v-for="item in exhortlist" :key="item.code" :label="item.name" :value="item.name">
													</el-option>
												</el-select>
											</div>
										</div>
										<div class="inputbox">
											<label>单次计量</label>
											<div>
												<el-input v-model="value.drugDosage" placeholder="请输入内容"></el-input>
											</div>
										</div>
										<div class="inputbox">
											<label>计量单位</label>
											<div>
												<el-select v-model="value.drugUseUnit_name" placeholder="请选择" @change="getCode(value.drugUseUnit_name,unitlist,index,'drugUseUnit')">
													<el-option v-for="item in unitlist" :key="item.code" :label="item.name" :value="item.name">
													</el-option>
												</el-select>
											</div>
										</div>
										<div class="inputbox">
											<label>用药途径</label>
											<div>
												<el-select v-model="value.drugRoute_name" placeholder="请选择" @change="getCode(value.drugRoute_name,channellist,index,'drugRoute')">
													<el-option v-for="item in channellist" :key="item.code" :label="item.name" :value="item.name">
													</el-option>
												</el-select>
											</div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="usage">
										<div class="inputbox">
											<label>用药频率</label>
											<div>
												<el-select v-model="value.drugFrequency_name" placeholder="请选择" @change="getCode(value.drugFrequency_name,ratelist,index,'drugFrequency')">
													<el-option v-for="item in ratelist" :key="item.code" :label="item.name" :value="item.name">
													</el-option>
												</el-select>
											</div>
										</div>
										<div class="inputbox">
											<label>总剂量</label>
											<div>
												<el-input v-model="value.drugIntegraldose" placeholder="请输入内容"></el-input>
											</div>
										</div>
										<div class="inputbox">
											<label>用药天数</label>
											<div>
												<el-input v-model="value.drugDay" placeholder="请输入内容"></el-input>
											</div>
										</div>
										<div class="inputbox">
											<label>是否皮试</label>
											<div>
												<el-select v-model="value.skin" placeholder="请选择" @change="getCode(value.skin,skinlsit,index,'skinTest')">
													<el-option v-for="(item,index) in skinlsit" :key="item.code" :label="item.name" :value="item.name">
													</el-option>
												</el-select>
											</div>
										</div>
									</div>
								</div>

							</div>
						</el-col>
					</el-row>
				</div>
				<div v-else class="no_add_drug">
					<span>尚未添加药品</span>
				</div>
			</div>
			<!-- 诊断结果 -->
			<div class="result">
				<div class="title">
					<span class="shu"></span>
					<span>诊断结果</span>
				</div>
				<div class="disease">
					<div class="disease-box">
						<el-input placeholder="输选择病症名称" v-model="value" @input="bedchange"></el-input>
						<div class="disease-list" v-if="state">
							<span class="disease-name" v-for="item in newoptions" :key="item.code" @click="bindvalue(item.name,item.code)">{{item.name}}</span>
						</div>
						<!-- <el-select class="disease-list" filterable v-model="inquiryInfo.diagCode" placeholder="请选择疾病名称" @blur="bedchange" >
							<el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code"></el-option>
						</el-select> -->
					</div>
				</div>
				<div class="content">
					<label>
						<textarea v-model="inquiryInfo.reportTxt" cols="20" rows="5" style="resize:none;font-size: 16px;font-family:'微软雅黑'"
						 @blur="complete"></textarea>
						<div class="text" @click="heidText" v-if="text">请填写诊断意见</div>
					</label>
				</div>
			</div>
			<div class="doctorName">
				<!-- 签名 -->
				<div class="botCot">
					<div v-if="showGraph" @click="clickAutograph">
						<div class="graph">
							<span>点击签名</span>
						</div>
					</div>
					<div v-else>
						<div class="mask" @click="clearAutograph">
							<img :src="imgUrl" alt />
							<span>点击清除签名</span>
						</div>
					</div>
					<button class="uploadimg" @click="onuploadimg">上传处方图片</button>
					<button @click="open('isErrorLess')">发送至药师审核</button>
				</div>
			</div>
		</div>

		<div v-show="isShow" class="Mask">
			<!-- 选择弹出框 -->
			<div v-show="selDialog" class="selDialog">
				<div class="sel">
					<span>选择患者档案</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('selDialog')" alt />
				</div>
				<div class="content">
					<div class="option" v-for="(v, i) in patientList" :key="i">
						<el-radio v-model="archives" :label="i + 1">{{ v.realname }}</el-radio>
						<span class="time">{{ v.add_time.split(" ")[0] }}</span>
					</div>
				</div>
				<button @click="savaPatient()">保存</button>
			</div>
			<!-- 编辑弹出框 -->
			<div v-show="editDialog" class="editDialog">
				<div class="sel">
					<span>编辑患者信息</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('editDialog')" alt />
				</div>
				<div class="content editbox">
					<table>
						<tr>
							<td>
								<span class="title">姓名</span>
								<input type="text" :placeholder="inquiryInfo.realname" v-model="inquiryInfo.realname" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">性别</span>
								<input type="radio" name="sex" :checked="inquiryInfo.sex === 1" @click="inquiryInfo.sex = 1" /> 男
								<input type="radio" name="sex" :checked="inquiryInfo.sex === 2" @click="inquiryInfo.sex = 2" /> 女
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">年龄</span>
								<input type="text" :placeholder="inquiryInfo.age" v-model="inquiryInfo.age" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">体重</span>
								<input type="text" :placeholder="inquiryInfo.weight" v-model="inquiryInfo.weight" />
								kg
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">身高</span>
								<input type="text" :placeholder="inquiryInfo.height" v-model="inquiryInfo.height" />
								cm
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">电话</span>
								<input type="text" :placeholder="inquiryInfo.mobile" v-model="inquiryInfo.mobile" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">现住址</span>
								<input type="text" :placeholder="inquiryInfo.address" v-model="inquiryInfo.address" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">联系人</span>
								<input type="text" :placeholder="inquiryInfo.linkman" v-model="inquiryInfo.linkman" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">与联系人关系</span>
								<el-select v-model="inquiryInfo.relationship" placeholder="请选择" @change="getrelaname('Choice')">
									<el-option v-for="item in relalist" :key="item.code" :label="item.name" :value="item.code">
									</el-option>
								</el-select>
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">联系人地址</span>
								<input type="text" :placeholder=" inquiryInfo.linkmanAddr " v-model="inquiryInfo.linkmanAddr" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">联系人电话</span>
								<input type="text" :placeholder="inquiryInfo.linkmanTele" v-model="inquiryInfo.linkmanTele" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">过敏史</span>
								<input type="text" :placeholder="inquiryInfo.allergy" v-model="inquiryInfo.allergy" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">遗传史</span>
								<input type="text" :placeholder="inquiryInfo.genetic_disease" v-model="inquiryInfo.genetic_disease" />
							</td>
						</tr>
						<tr>
							<td>
								<span class="title">身份证</span>
								<input type="text" :placeholder="inquiryInfo.idcard" v-model="inquiryInfo.idcard" />
							</td>
						</tr>

					</table>
					<div>
						<span class="title">患者病情</span>
						<el-select v-model="condition" placeholder="请选择" @change="Condition">
							<el-option v-for="item in hosp" :key="item.code" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</div>
					<div>
						<span class="title">付费方式</span>
						<el-select v-model="payname" placeholder="请选择付费方式">
							<el-option v-for="item in pay" :key="item.code" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</div>
				</div>
				<div>
					<button @click="savaPatientInfo">保存</button>
				</div>
			</div>
			<!-- 诊断结果编辑 -->
			<div v-show="resultDialog" class="resultDialog">
				<div class="sel">
					<span>诊断结果</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('resultDialog')" />
				</div>
				<div class="content">
					<textarea v-model="inquiryInfo.diagnosis"></textarea>
				</div>
				<button @click="savaDiagnosisResult">保存</button>
			</div>
			<!-- 添加商品 -->
			<div v-show="addGoodDialog" class="addGoodDialog">
				<div class="sel">
					<span>添加商品</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('addGoodDialog')" />
				</div>
				<div class="content">
					<div>
						<div class="jibie">
							<span>一级药品分类</span>
							<el-select v-model="class1value" placeholder="请选择" @change="changeTwoItems">
								<el-option v-for="(item,index) in drugItems" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<!-- <select value="全部" @change="changeTwoItems($event)">
								<option style="display:none"></option>
								<option selected v-for="(item, index) in drugItems" :key="index">{{item.category_name}}</option>
							</select> -->
						</div>
						<div class="jibie">
							<span>二级药品分类</span>
							<el-select v-model="class2value" placeholder="请选择" @change="changeThreeItems">
								<el-option v-for="(item,index) in towDrugItems" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
							<!-- 	<select @change="changeThreeItems($event)">
								<option style="display:none"></option>
								<option v-for="(item, index) in towDrugItems" :key="index">{{item.category_name}}</option>
							</select> -->
						</div>
						<div class="jibie  class3">
							<span>三级药品分类</span>
							<el-select v-model="class3value" placeholder="请选择" v-el-select-loadmore="loadmore">
								<el-option v-for="(item,index) in threeDrugItems" :key="item.value" :label="item.label" :value="item.value">

								</el-option>
							</el-select>
							<!-- 	<select ref="select">
								<option style="display:none"></option>
								<option v-for="(item, index) in threeDrugItems" :key="index">{{item.goods_name}}</option>
							</select> -->
						</div>
					</div>
					<!-- 				<div class="zhuji">
						<i class="el-icon-search"></i>
						<input type="text" placeholder="请输入助记码" />
					</div> -->
					<div class="name">
						<div>
							<el-radio v-model="radio" label="1">
								<span>x1 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="2">
								<span>x2 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="3">
								<span>x3 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="4">
								<span>x4 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="5">
								<span>x5 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="6">
								<span>x6 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="7">
								<span>x7 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="8">
								<span>x8 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="9">
								<span>x9 （袋 / 盒）</span>
							</el-radio>
						</div>
						<div>
							<el-radio v-model="radio" label="10">
								<span>x10 （袋 / 盒）</span>
							</el-radio>
						</div>
					</div>
				</div>
				<button @click="sendAddGood">确认添加</button>
			</div>
			<!-- 签名功能 -->
			<div v-show="autograph" class="autograph">
				<div class="sel">
					<span>医生签名</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('autograph')" />
				</div>
				<div class="content">
					<Autograph @getImgUrl="getImgUrl" />
				</div>
			</div>
			<!-- 确认处方单无误 -->
			<div v-show="isErrorLess" class="isErrorLess">
				<div class="sel">
					<span>提示</span>
					<img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="close('isErrorLess')" />
				</div>
				<div class="content">确认处方单信息无误?</div>
				<button @click="close('isErrorLess')">否</button>
				<button @click="sendPharmacistExamine">是</button>
			</div>
		</div>
		<div class="uploadimgbox" v-show="unloadimg">
			<div class="content">
				<div class="uploadtitle"><span>上传处方单</span><span class="el-icon-close" @click="clearimg"></span></div>
				<div class="upload_img">
					<div class="upload_btn" v-if="imageUrl==''">
						<p class="el-icon-camera icon"></p>
						<p>点击上传处方单图片</p>
					</div>
					<div class="upload_btn" v-else>
						<img :src="imageUrl">
					</div>
					<input ref="upload_img" @change="OpenUpload" type="file" id="test-image-file" name="test" accept="image/gif, image/jpeg, image/png, image/jpg">
				</div>
				<div class="uploadbtn">
					<el-button type="danger" plain @click="clearimg">取消</el-button>
					<el-button type="danger" plain @click="Select">选择药师发送</el-button>
				</div>
			</div>
		</div>
		<div class="doclist" v-show="pharmacist">
			<div class="doctitle">选择药师 <span class="el-icon-close" @click="pharmacist=false"></span></div>
			<div class="peoples">
				<div class="peobox" v-for="(item,index) in doclists" :key="index" @click="opendoc(item.uid)">
					<div class="head">
						<img :src="item.avatar" alt="">
					</div>
					<span>{{item.user_login}}</span>
				</div>
			</div>
			<div class="docbottom"><span class="el-icon-d-arrow-right"></span></div>
		</div>
	</div>
</template>

<script>
	import Autograph from "@/components/autograph";

	// 获取药品列表
	import {
		getAllCate
	} from "@/request/personnel";
	// 获取药品详细信息
	import {
		getCateIndex
	} from "@/request/personnel";
	// 发送至药师审核
	import {
		inquiryAdd
	} from "@/request/personnel";
	import {
		getDiseaseList
	} from "@/request/personnel";
	import {
		getBedInfor
	} from "@/request/personnel";
	export default {
		props: ["inquiryInfo", "patientList"],
		components: {
			Autograph
		},
		data() {
			return {
				state: false,
				text: true, //text显示和隐藏
				isShow: false, //遮罩层显示
				selDialog: false,
				editDialog: false,
				resultDialog: false,
				addGoodDialog: false,
				showGraph: true,
				isErrorLess: false,
				radio: 0,
				archives: 1,
				drugItems: [], // 药品各级分类
				towDrugItems: [],
				threeDrugItems: [],
				imgUrl: "", // 签名图片URl
				autograph: false,
				isAddDrug: false, // 是否添加药品
				addGoods: [], // 医生添加的商品
				selectGood: "", // 当前选择的商品
				errorLess: false, // 问诊处方单是否有误
				value: "", //选择病的名字
				options: [],
				newoptions: [],
				hosp: [],
				condition: "", //病情状况
				exhortlist: [], //嘱咐类型
				exhort: "",
				onlydose: "1", //单次剂量
				alldose: "1", //总剂量
				eatday: "1", //用药天数
				unitlist: [], //药品单位列表
				unit: "",
				channellist: [], //用药途径
				channel: '',
				ratelist: [], //用药频率
				rate: '',
				skinlsit: [{
					code: "0",
					name: "不需要皮试"
				}, {
					code: "1",
					name: "需要皮试"
				}], //是否皮试
				skin: "",
				pay: [],
				payname: "",
				relalist: [],
				rela: "",
				imageUrl: '',
				unloadimg: false,
				pharmacist: false,
				doclists: "",
				sendtype: 0,
				class1: "",
				class1value: "",
				class2value: "",
				class3value: "",
				page: 1,
				class3goodlist: [],
			};
		},
		created() {
			getBedInfor({
				table: "DOMESTICRELATION",
				
			}).then(res => {
				this.relalist = res.data;
			
			});
			console.log(this.inquiryInfo);
			// 获取1级分类
			getAllCate().then(res => {
				this.class1 = res.data;
				for (let i in res.data) {
					this.drugItems.push({
						value: i,
						label: res.data[i].category_name,
					})
				}
				console.log(this.class1);
			});
			// 疾病代码
			getBedInfor({
				table: "ICD10"
			}).then(res => {
				this.options = res.data;
			});
			// 患者病情
			getBedInfor({
				table: "HOSPITALIZATIONSTATE"
			}).then(res => {
				this.hosp = res.data;
			});
			// 支付方式
			getBedInfor({
				table: "MEDPAYTYPE"
			}).then(res => {
				this.pay = res.data;
			});
			// 医嘱项目分类 
			getBedInfor({
				table: "ORDERCLASSIFICATION"
			}).then(res => {
				this.exhortlist = res.data;
			});
			//药品单位
			getBedInfor({
				table: "DRUGDOSAGEUNIT"
			}).then(res => {
				this.unitlist = res.data;
			});
			//用药途径
			getBedInfor({
				table: "DRUGROUTE"
			}).then(res => {
				console.log(res);
				this.channellist = res.data;
			});

			getBedInfor({
				table: "DRUGFREQUENCY"
			}).then(res => {
				this.ratelist = res.data;
			});
		},
		directives: {
			'el-select-loadmore': {
				inserted(el, binding) {
					const SELECTWRAP_DOM = el.querySelector(
						'.el-select-dropdown .el-select-dropdown__wrap'
					);
					SELECTWRAP_DOM.addEventListener('scroll', function() {
						const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
						if (condition) {
							binding.value();
						}
					});
				}
			}
		},
		filters:{
			gettime(value){
				console.log(value);
				let time=new Date();
				let oldtime= new Date(parseInt(value) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
				 return time.getFullYear()-oldtime.slice(0,4);
			}
		},
		methods: {
			OpenUpload(e) {
				console.log(e);
				let files = e.target.files[0];
				this.formData = new FormData();
				this.formData.append("file", files);
				this.formData.append("token", this.$token);
				let url = "";
				let reader = new FileReader();
				reader.readAsDataURL(files)
				let that = this;
				reader.onload = function() {
					url = this.result.substring(this.result.indexOf(",") + 1);
					that.upload_file();
				}
			},
			// 上传
			upload_file() {
				this.$axios.post("/api/file/upload", this.formData).then(res => {
					if (res.data.code == 1) {
						this.imageUrl = res.data.data.file.url;

					}
				});
			},
			onuploadimg() {
				this.unloadimg = true;
				this.sendtype = 1;
			},
			opendoc(id) {
				this.pharmacist = false;
				this.unloadimg = false;
				this.$confirm('发送给该药师?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.sendPharmacist(id)
				}).catch(() => {});
			},
			// 发送个给药师
			sendPharmacist(id) {
				if (this.sendtype == 1) {
					this.$axios.post("/api/Prescription/doctorAdd", {
						token: this.$token,
						inquiry_uid: this.inquiryInfo.patient_uid,
						pic: this.imageUrl,
						yaoshi_uid: id
					}).then(res => {
						if (res.data.code == 1) {
							this.$message.success("发送成功")
						}
					})
				} else {
					// 发送至药师审核
					let data = {
						token: this.$token,
						inquiry_id: this.inquiryInfo.id,
						goods_list_json: this.addGoods,
						yaoshi_uid: id,
						inquiry_patient_json: {
							...this.inquiryInfo
						},
						audit_sign: this.imgUrl
					};

					inquiryAdd(data).then(res => {
						if (res.code == 1) {
							this.$message({
								type: "success",
								message: "发送成功"
							});
						} else {
							this.$message({
								type: "error",
								message: res.msg
							});
						}

					});

				}

			},
			// 选择药师
			Select() {
				if (this.imageUrl == '') {
					this.$message.error("请先选择要上传的图片")
				} else {
					this.pharmacist = true;
					this.$axios.post("/api/doctor/getOnlineList", {
						cert_type: 3,
						page_size: 1000,
						token: this.$token,
					}).then(res => {
						this.doclists = res.data.data.data;
					})
				}

			},
			// 取消上传处方
			clearimg() {
				this.imageUrl = "";
				this.pharmacist = false;
				this.unloadimg = false;
			},
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
				console.log(this.imageUrl)
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			getrelaname(value) {
				let name = "";
				if (value == null) {
					name = "未填写";
				} else if (value != "Choice") {
					for (let i in this.relalist) {
						if (value == this.relalist[i].code) {
							name = this.relalist[i].name;
						}
					}
				} else {
					for (let i in this.relalist) {
						if (this.inquiryInfo.relationship == this.relalist[i].code) {
							this.inquiryInfo.relationship_name = this.relalist[i].name;
						}
					}
				}
				return name;
			},
			getname(value) {
				let name = "";
				if (value == "") {
					name = "无";
				} else {
					for (let i in this.pay) {
						if (value == this.pay[i].code) {
							name = this.pay[i].name;
						}
					}
				}
				return name;
			},
			// 类型
			getCode(name, list, index, str) {
				for (let i in list) {
					if (list[i].name == name) {
						this.addGoods[index][str] = list[i].code
					}
				}
			},
			extract(value, json) {
				let code = "";
				for (let i in json) {
					if (json[i].name == value) {
						code = json[i].code;
					}
				}
				return code;
			},
			Condition() {
				for (let i in this.hosp) {
					if (this.hosp[i].name == condition) {
						console.log(i)
						inquiryInfo.condition = this.hosp[i].code;
					}
				}
			},
			bindvalue(name, code) {
				this.value = name;
				this.inquiryInfo.diagnosis = name;
				this.inquiryInfo.diagCode = code;
				this.state = false;
			},
			bedchange() {
				this.newoptions = [];
				if (this.value != "") {
					for (let i in this.options) {
						if (this.options[i].name.indexOf(this.value) != -1) {
							this.newoptions.push(this.options[i]);
						}
						if (i == this.options.length - 1) {
							this.state = true;
							console.log(this.state)
						}
					}

				} else {
					this.state = false;
				}

			},
			heidText() {
				this.text = false;
			},
			complete() {
				this.inquiryInfo.reportTxt == "" ? this.text = true : this.text = false;
			},
			close(e) {
				this[e] = false;
				this.isShow = false;
			},
			open(e) {
				this[e] = true;
				this.isShow = true;
				if (e === "autograph") {
					this.autograph = false;
				}
				this.payname = this.getname(this.inquiryInfo.medicalPayment);
			},
			handleChange() {},
			// 二级分类
			changeTwoItems(v) {
				this.towDrugItems = [];
				this.threeDrugItems = [];
				this.class2value = "";
				this.class3value = "";
				for (let i of this.class1[v].childData) {
					this.towDrugItems.push({
						value: i.id,
						label: i.category_name,
					})
				}
				console.log(this.threeDrugItems);
			},
			// 三级分类
			changeThreeItems(v) {
				this.page = 1;
				this.goodid = v;
				this.class3value = "";
				this.threeDrugItems = [];
				this.class3goodlist = [];
				getCateIndex({
					category_id: v,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			// 三级下拉加载
			loadmore() {
				getCateIndex({
					category_id: this.goodid,
					page: this.page,
				}).then(res => {
					this.page++;
					for (let i of res.data.data) {
						this.class3goodlist.push(i);
						this.threeDrugItems.push({
							value: i.id,
							label: i.goods_name,
						});
					}
				});
			},
			// 确认添加药品
			sendAddGood() {
				// 医生添加的商品
				// 从第三级数组中筛选出要选择的药品
				console.log(this.class3goodlist);
				let result = this.class3goodlist.find(
					item => item.id === this.class3value
				);

				let flag = false;
				for (let i of this.addGoods) {
					if (i.goods_id == result.id) {
						flag = true;
					} else {
						flag = false;
					}
				}
				if (flag) {
					this.$message({
						type: "error",
						message: "商品已经存在"
					});
				} else {
					console.log(this.exhort);
					this.addGoods.push({
						goods_name: result.goods_name,
						goods_img: result.goods_img,
						num: this.radio,
						goods_id: result.id,
						orderClassification: "",
						orderCode: this.exhort,
						drugFrequency: this.rate,
						drugDosage: this.onlydose,
						drugUseUnit: this.unit,
						drugIntegraldose: this.alldose,
						drugRoute: " ",
						skinTest: "",
						skin: this.skin,
						drugDay: this.eatday,
						unit: "袋/盒",
					});

					this.isAddDrug = true;
				}
				this.isShow = false;
				this.addGoodDialog = false;
			},
			getImgUrl(imgUrl) {
				// 获取签名图片并保存
				this.imgUrl = imgUrl;
				this.showGraph = false;
				this.isShow = false;
				this.autograph = false;
			},
			clickAutograph() {
				// 签名
				this.isShow = true;
				this.autograph = true;
			},
			clearAutograph() {
				// 清除签名
				this.imgUrl = "";
				this.showGraph = true;
			},
			savaPatientInfo() {
				// 保存患者信息

				if (this.payname == "") {
					this.$message({
						type: "error",
						message: "请选择付费方式"
					});
				} else {
					for (let i in this.pay) {
						if (this.payname == this.pay[i].name) {
							this.inquiryInfo.medicalPayment = this.pay[i].code;
						}
					}
					this.isShow = false;
					this.editDialog = false;
				}
			},
			savaPatient() {
				// 保存患者档案
				if (this.patientList.length == 0) return;
				let v = this.patientList.slice(this.archives - 1, this.archives)[0];
				this.inquiryInfo = v;
				console.log(this.inquiryInfo)
				this.isShow = false;
				this.selDialog = false;
			},
			savaDiagnosisResult() {
				// 保存诊断结果
				this.inquiryInfo.diagnosis.length == 0 ? this.text = true : this.text = false;
				this.isShow = false;
				this.resultDialog = false;
			},

			cancelGood(good) {
				// 取消添加商品
				let index = this.addGoods.indexOf(good);
				if (index != -1) this.addGoods.splice(index, index + 1);
			},
			sendPharmacistExamine() {
				if (this.condition != "") {
					for (let i in this.hosp) {
						if (this.condition == this.hosp[i].name) {
							this.inquiryInfo.condition = this.hosp[i].code;
						}
					}
				} else {
					this.$message({
						type: "error",
						message: "请选择患者病情"
					});
					return;
				}

				if (this.addGoods.length == "0") {
					this.$message({
						type: "error",
						message: "药品不能为空"
					});
					return;
				}
				if (this.inquiryInfo.diagnosis == "") {
					this.$message({
						type: "error",
						message: "疾病名称不能为空"
					});
					return;
				}
				if (this.inquiryInfo.reportTxt == "") {
					this.$message({
						type: "error",
						message: "诊断结果不能为空"
					});
					return;
				}

				if (this.imgUrl == "") {
					this.$message({
						type: "error",
						message: "签名不能为空"
					});
					return;
				}
				this.$axios.post("/api/doctor/getOnlineList", {
					cert_type: 3,
					page_size: 1000,
					token: this.$token,
				}).then(res => {
					this.doclists = res.data.data.data;
				})
				this.pharmacist = true;
				this.sendtype = 2;
				this.isShow = false;
				this.isErrorLess = false;


			}
		}
	};
</script>

<style lang="less" scoped>
	.editbox {
		input[type=text] {
			border: 1px solid #E5E5E5 !important;
			width: 192px;
			padding: 7px 10px;
		}

		.el-select {
			border: 1px solid #E5E5E5 !important;
			margin: 5px 0;
			width: 216px;

			/deep/.el-input__inner {

				height: 30px !important;
			}
		}

	}

	.upload_img {
		border: 1px solid #ccc;
		height: 200px;
		margin-top: 20px;
		position: relative;

		input {
			width: 150px;
			height: 150px;
			background-color: red;
			position: absolute;
			top: 25px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
		}

		.upload_btn {
			width: 150px;
			height: 150px;
			margin: 0 auto;
			background-color: #F0F0F0;
			margin-top: 25px;
			text-align: center;
			border-radius: 10px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			.icon {
				margin-top: 30px;
				font-size: 50px;
				color: #CCCCCC;
				margin-bottom: 10PX;
			}

			P {
				font-size: 14PX;
				color: #CCCCCC
			}
		}
	}

	.doclist {
		width: 200px;
		background-color: #fff;
		position: fixed;
		left: 50%;
		top: 46%;
		padding-bottom: 20px;
		box-shadow: 0px 0px 5px #CCCCCC;
		transform: translate(-50%, -50%);
		z-index: 999999;

		.doctitle {
			text-align: center;
			line-height: 30px;
			position: fixed;
			top: 0px;
			width: 100%;
			background-color: #fff;
			border-bottom: 1px solid #EAEAEA;
			font-size: 14px;

			span {
				position: absolute;
				right: 10px;
				top: 7px;
			}
		}

		.peoples::-webkit-scrollbar {
			display: none
		}

		.peoples {
			margin-top: 30px;
			height: 200px;
			overflow: auto;

			.peobox:hover {
				background-color: #F0F0F0;
			}

			.peobox {
				display: flex;
				border-bottom: 1px solid #F0F0F0;
				padding: 5px;
				cursor: pointer;

				span {
					display: inline-block;
					margin-left: 10px;
					line-height: 40px;
				}

				.head {
					border-radius: 100%;
					width: 40px;
					height: 40px;
					overflow: hidden;

					img {
						width: 40px;
						height: 40px;
						display: inline-block;
					}

				}
			}
		}

		.docbottom {
			position: absolute;
			bottom: 0;
			width: 100%;
			// height: 20px;
			text-align: center;

			// background-color: black;
			span {
				transform: rotate(90deg);
			}
		}
	}

	.uploadimgbox {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 9999;

		.content {
			width: 500px;
			height: 400px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.uploadtitle {
				width: 100%;
				height: 30px;
				line-height: 30px;
				background-color: #E5E5E5;
				padding: 0 10px;
				display: flex;
				font-size: 14px;
				justify-content: space-between;

				span {
					display: inline-block;
					line-height: 30px;
				}
			}

			.imgbox {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 50px;

				/deep/.avatar-uploader .el-upload {
					width: 300px;
					height: 200px;
					border: 1px dashed #ccc;
					border-radius: 6px;
					cursor: pointer;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.avatar-uploader .el-upload:hover {
					border-color: #409EFF;
				}

				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 178px;
					height: 178px;
					line-height: 178px;
					text-align: center;
				}

				.avatar {
					width: 178px;
					height: 178px;
					display: block;
				}
			}

			.uploadbtn {
				position: absolute;
				bottom: 20px;
				left: 50%;
				width: 250px;
				transform: translateX(-50%);
				display: flex;
				justify-content: space-between;

			}

		}

	}

	.content .title {
		display: inline-block;
		width: 98px !important;
		margin-right: 20px !important;
	}

	.usage .inputbox {
		display: flex;
		margin-bottom: 10px;

		label {
			width: 60px;
			line-height: 24px;
		}

		div {
			width: 130px;
			margin-left: 5px;
		}

		/deep/.el-input__inner {
			height: 24px;
		}

		/deep/.el-input__icon {
			line-height: 20px;
		}

	}

	.instructions {
		display: flex;
		justify-content: space-between;
	}

	.drugsnumber {
		display: inline-block;
		position: relative;
		top: 4px;
		left: 10px;
	}

	.disease-name {
		display: block;
		height: 30px;
		width: 90%;
		line-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		border-bottom: 1px solid #f5f5f5;
		padding: 0 10px;
	}

	.disease-name:hover {
		background: #E5E5E5;
	}

	.rightCot {
		overflow-y: auto;
		height: calc(100% - 55px);
	}

	.disease {
		background-color: #f7f7f7;
		padding-left: 13px;
		font-size: 14px;

		.disease-box {
			width: 300px;
			height: 50px;
			padding-top: 5px;
			line-height: 35px;
			position: relative;
		}
	}

	.disease-list {
		position: absolute;
		top: 45px;
		z-index: 999999999;
		width: 200px;
		height: 240px;
		background: #ffff;
		border: 1px solid #f5f5f5;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.doctorName {
		height: 140px;
		width: 100%;
		position: relative;
	}

	.result .content {
		position: relative;
	}

	.result .content .text {
		width: 100%;
		text-align: center;
		line-height: 120px;
		position: absolute;
		top: 0;
		color: #797979;
	}

	.rightCot {
		// height: 100%;
		width: 37.5%;
		position: absolute;
		min-width: 450px;
		right: 0;
	}

	.topCot {
		overflow: hidden;

		.title {
			background: rgba(230, 255, 249, 1);
			margin-top: 10px;
			height: 30px;
			line-height: 30px;
			padding-left: 20px;
			font-size: 18px;
			font-weight: 600;

			.shu {
				display: inline-block;
				width: 4px;
				height: 20px;
				background-color: #38eec1;
				float: left;
				margin-top: 6px;
				margin-right: 8px;
			}

			.fr {
				font-size: 16px;
				color: #38eec1;

				span {
					margin: 0 10px;
					cursor: pointer;
				}
			}
		}

		.patInfo {
			margin-bottom: 15px;

			.title {
				margin-top: 0px;
			}

			.content {
				margin-left: 15px;

				.space {
					display: inline-block;
					width: 70px;
					color: #797979;
					text-align-last: justify;
					margin-right: 10px;
				}
			}
		}

		.result {
			.content {
				padding: 0 10px 10px 12px;
				background-color: #f7f7f7;

				textarea {
					width: calc(100% - 30px);
					border: none;
					padding: 15px;
					font-size: 18px;
				}
			}
		}

		.drugs {
			margin-bottom: 15px;

			.no_add_drug {
				line-height: 140px;
				text-align: center;
				color: #666;
			}

			.drugInfo {
				overflow-y: auto;
				overflow-x: hidden;
				font-size: 14px;
				height: 240px;

				>.el-row {
					position: relative;
					padding-bottom: 10px;
					border-bottom: 1px solid #eee;
					margin-left: 5px !important;

					.grid-content.bg-purple {
						>img {
							position: absolute;
							right: 30px;
							top: 20px;
							right: 25px;
							cursor: pointer;
						}
					}
				}

				.el-input-number {
					float: right;
				}

				.explain {
					margin: 0 12px;
				}

				.el-input {
					display: inline-block;
				}

				.title {
					margin-top: 0px;
					margin-bottom: 20px;
				}

				.grid-content.bg-purple.left>div {
					margin-top: 10px;
					margin-left: 8px;
				}

				.drugInfo .el-row:nth-child(1) {
					line-height: 40px;
				}

				.grid-content.bg-purple.num span {
					margin-left: 50px;
				}

				.grid-content.bg-purple.del {
					color: red;
					cursor: pointer;
				}

				.name {
					border: 1px solid #f5f5f5;

					>span {
						width: 50%;
						display: inline-block;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					img {
						vertical-align: -10px;
						margin-right: 15px;
						width: 40px;
						height: 40px;
					}
				}
			}
		}
	}

	.botCot {
		width: 100%;
		border-top: 1px solid #38eec1;
		height: 140px;
		position: absolute;
		bottom: 0px;
		left: 0px;

		>div {
			width: 210px;
			height: 105px;
			position: absolute;
			top: 30px;
			left: 10px;

			.mask {
				position: absolute;
				left: 0px;
				top: 0px;
				width: 210px;
				height: 105px;
				background: rgba(0, 0, 0, 0.5);
				line-height: 110px;
				text-align: center;
				color: #fff;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}

				span {
					position: absolute;
					top: 0px;
					color: #fff;
					left: 50px;
				}
			}

			.graph {
				box-sizing: border-box;
				border: 2px solid #ccc;
				height: 100%;
				text-align: center;
				cursor: pointer;

				span {
					display: inline-block;
					margin-top: 40px;
				}
			}
		}

		button {
			padding: 10px 35px;
			background: #38eec1;
			border-radius: 12px;
			position: absolute;
			top: 100px;
			margin-left: 240px;
			cursor: pointer;
		}

		.uploadimg {
			position: absolute;
			top: 50px;
			width: 160px;
			height: 40px;

		}
	}

	.Mask {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 40;
		background: rgba(0, 0, 0, 0.5);

		.selDialog {
			width: 600px;
			height: 400px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 40px 20px 20px;

				.option {
					display: inline-block;
					width: 40%;
					line-height: 35px;
					margin-left: 20px;

					.el-radio {
						margin-left: 20px;
						width: 55px;
					}

					span {
						color: #666;
					}
				}
			}

			button {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 260px;
				cursor: pointer;
			}
		}

		.editDialog {
			width: 600px;
			padding: 12px;
			height: 600px;
			position: relative;
			padding-bottom: 50px;
			top: 50%;
			transform: translateY(-50%);
			margin: auto;
			background: #fff;
			overflow: auto;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 25px 20px 20px;

				table {
					width: 100%;

					tr {
						span {
							display: inline-block;
							width: 70px;
							margin-right: 10px;
							text-align-last: justify;
						}

						height: 38px;
					}
				}
			}

			button {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				margin: 0 auto;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				cursor: pointer;
			}
		}

		.resultDialog {
			width: 600px;
			height: 400px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 40px 20px 20px;

				textarea {
					width: calc(100% - 16px);
					padding: 8px;
					overflow-y: scroll;
					resize: none;
					outline: none;
					height: 230px;
					font-size: 20px;
				}
			}

			button {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 260px;
				cursor: pointer;
			}
		}

		.addGoodDialog {
			width: 600px;
			height: 650px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 40px 20px 20px;

				.jibie {
					height: 35px;
					display: flex;

					/deep/.el-select {
						height: 30px;
						width: 400px;
						margin-left: 14px;
						border: 1px solid #CCCCCC;
						border-radius: 5px;

						.el-input__inner {
							height: 27px;
							line-height: 27px;
							border-radius: 5px;

						}

						/deep/.el-input__icon {
							line-height: 27px !important;
						}
					}

				}

				.zhuji {
					i {
						margin-top: 10px;
						margin-right: 14px;
					}
				}

				.name {
					border-top: 20px solid #ddd;
					margin-top: 20px;
					padding: 10px;

					span {
						margin-left: 12px;
					}
				}
			}

			button {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 260px;
				cursor: pointer;
			}
		}

		.autograph {
			width: 600px;
			height: 370px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				margin: 20px 20px 20px;
				height: 240px;
				border: 1px solid #ccc;
			}
		}

		.isErrorLess {
			width: 460px;
			height: 260px;
			padding: 12px;
			position: absolute;
			left: 0px;
			top: 0px;
			bottom: 0px;
			right: 0px;
			margin: auto;
			background: #fff;

			div {
				img {
					width: 15px;
					height: 15px;
					float: right;
					cursor: pointer;
				}
			}

			.content {
				padding: 60px 20px 20px;
				font-size: 24px;
				text-align: center;
			}

			button:nth-of-type(1) {
				padding: 5px 25px;
				background: #fff;
				color: red;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 150px;
				cursor: pointer;
			}

			button:nth-of-type(2) {
				padding: 5px 25px;
				background: #f00000;
				color: #fff;
				border: 1px solid red;
				border-radius: 12px;
				position: absolute;
				bottom: 20px;
				outline: 0;
				left: 260px;
				cursor: pointer;
			}
		}
	}
</style>
