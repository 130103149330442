<template>
  <div class="main">
    <Nav @finishInquiry="finishInquiry" :inquiryName="inquiryName" />
    <InputView v-if="chat_type_temp != 1" :userMessage="userMessage" @receiveMessage="receiveMessage" />
    <Video v-if="chat_type_temp == 1" :inquiryInfo="inquiryInfo" />
    <rightCot :inquiryInfo="inquiryInfo" :patientList="patientList" />
  </div>
</template>

<script>
import Nav from "./nav";
import InputView from "./InputView";
import rightCot from "./rightCot";
import Video from "./video";
export default {
  props: [
    "userMessage",
    "inquiry_Info",
    "inquiryName",
    "patientList",
    "chat_type"
  ],
  data(){
    return {
      chat_type_temp: 0,
      inquiryInfo: {}
    }
  },
  components: {
    Nav,
    InputView,
    rightCot,
    Video
  },
  created() {
    this.chat_type_temp = this.chat_type;
    this.inquiryInfo = this.inquiry_Info;
  },
  methods: {
    receiveMessage(msg) {
      this.$emit("sendMessage", msg);
    },
    finishInquiry() {
      this.$emit("changeOnline", "finish");
    }
  }
};
</script>

<style scoped>
.main {
  min-width: 1200px;
  width: 100%;
  height: calc(100% - 66px);
}
</style>